<template>
  <div>
    <!-- 头部 -->
    <div class="head flex_lr">
      <div class="flex_row" style="width: 30%;">  
        <i class="ifont icone-caidanshouqi hand" @click="clickBtn" style="transform: rotate(180deg);padding:0 10px;"/>
      </div>
      <div class="flex_center" style="font-size:18px; width: 30%;color:#c49f29;font-weight:bold;">MTDAO</div>
      <!-- <div class="flex_row">
        <img src="@/image/s_06.png" alt="">
        <div style="font-size: 20px;"><span style="color: #FF0000;">M</span><span style="color: #FF9C00;">T</span><span style="color: #7E00FF;">DAO</span></div>
      </div> -->
      <div class="flex_row" style="overflow: hidden; width: 30%; text-align: right;" @click="getadd">
        <!-- <i class="ifont icone-qianbao1"></i> -->
        <div class="head_user" v-if="wallet_address&&wallet_address!=''">{{wallet_address.length>15?wallet_address.slice(0,5)+'****'+wallet_address.slice(wallet_address.length-4):wallet_address}}</div>
      </div>
    </div>
    <!-- 侧边菜单 -->
    <div class="menu" v-if="is_menu" @click.self="closeDrop" @touchmove.self.prevent @mousewheel.self.prevent>
      <div class="menu_bg flex_ud" :class="{ active: isActive, close: isClose }">
        <div style="overflow: auto;height: 0;flex: 1;">
          <!-- 首页 -->
          <div class="flex_row menu_list" @click="replacePage('/')">
            <img src="@/image/menu/MTDAO.png" alt="">
            <div>MTDAO</div>
          </div>
          <!-- MTG -->
          <div class="flex_row menu_list" @click="replacePage('/nft')">
            <img src="@/image/menu/mtg_33.png" alt="">
            <div>MTG</div>
          </div>
          <!-- MT -->
          <div class="flex_row menu_list"  @click="replacePage('/mt')">
            <img src="@/image/menu/caidanlan_07.png" alt="">
            <div>MT</div>
          </div>
          <!-- 系统公告 -->
          <div class="flex_row menu_list" @click="toPage('/notice')">
            <img src="@/image/menu/caidanlan_10.png" alt="">
            <div>{{$t('layout.Notice')}}</div>
          </div>
          <!-- 邀请分享 -->
          <div class="flex_row menu_list" @click="goShare">
            <img src="@/image/menu/yaoqing_37.png" alt="">
            <div>{{$t('layout.Share')}}</div>
          </div>
          <!-- 我的社区 -->
          <div class="flex_row menu_list" @click="toPage('/myCommunity')">
            <img src="@/image/menu/caidanlan_13.png" alt="">
            <div>{{$t('layout.Community')}}</div>
          </div>
          <!-- ULM矿池 -->
          <div class="flex_row menu_list" @click="toPage('/miner')">
            <img src="@/image/menu/caidanlan_16.png" alt="">
            <div>{{$t('layout.Pool')}}(ULME)</div>
          </div>
          <div class="flex_row menu_list" @click="toPage('/miner_MT')">
            <img src="@/image/menu/caidanlan_16.png" alt="">
            <div>{{$t('layout.Pool')}}(MT)</div>
          </div>
          <!-- 基金池 -->
          <!-- <div class="flex_lr menu_list" @click="openMenu('fundPool')">
            <div class="flex_row">
              <img src="@/image/menu/jijinchi.png" alt="">
              <div>{{$t('layout.fundPool')}}</div>
            </div>
            <i class="ifont icone-shouqi" :style="menu_open_list.indexOf('fundPool')>-1?'transform: rotate(180deg)':''"></i>
          </div>
          <div class="menu_list_open" v-if="menu_open_list.indexOf('fundPool')>-1">

            <div class="flex_row menu_list" @click="toPage('/Incentive')">
              <div>{{$t('layout.Incentive')}}</div>
            </div>
     
            <div class="flex_row menu_list" @click="toPage('/Compensate')">
              <div>{{$t('layout.Compensate')}}</div>
            </div>
          </div> -->
          <!-- <div class="flex_row menu_list" @click="toPage('/Incentive')">
          <div class="flex_row">
              <img src="@/image/menu/jijinchi.png" alt="">
            <div>{{$t('layout.Incentive')}}</div>
            </div>
          </div> -->
          <!-- 黑洞计划 -->
          <div class="flex_row menu_list" @click="coming_soon">
            <img src="@/image/menu/heidong.png" alt="">
            <div>{{$t('layout.Destruction')}}</div>
          </div>
          <!-- NFT交易 -->
          <div class="flex_row menu_list" @click="toPage('/nftInfo')">
            <img src="@/image/menu/NFT.png" alt="">
            <div>{{$t('layout.NFT')}}</div>
          </div>
          <!-- 元宇宙生态家园 -->
          <div class="flex_row menu_list" @click="coming_soon">
            <img src="@/image/menu/jiayuan.png" alt="">
            <div>{{$t('layout.Metaverse')}}</div>
          </div>
          <!-- 系统设置 -->
          <div class="flex_row menu_list" @click="setUp">
            <img src="@/image/menu/caidanlan_21.png" alt="">
            <div>{{$t('layout.Set')}}</div>
          </div>
          <!-- 质押托管 -->
          <div class="flex_row menu_list" @click="toPage('/pledge_list')">
            <img src="@/image/menu/zhiya.png" alt="">
            <div>{{$t('layout.Pledge')}}</div>
          </div>
          <div class="flex_row menu_list" @click="toPage('/pledge_mt')">
            <img src="@/image/menu/zhiya.png" alt="">
            <div>{{$t('layout.PledgeMt')}}</div>
          </div>
          <!-- 常见问题 -->
          <!-- <div class="flex_row menu_list" @click="miner">
            <i  class="ifont icone-wenti"></i>
            <img src="@/image/menu/caidanlan_25.png" alt="">
            <div>{{$t('layout.Problem')}}</div>
          </div> -->
          <!-- 新手帮助 -->
          <!-- <div class="flex_row menu_list" @click="toPage('/help')">
            <div>{{$t('layout.Help')}}</div>
          </div> -->
          <!-- 购买 --> 
          <div class="flex_row menu_list" @click="goSwap">
            <img src="@/image/menu/caidanlan_29.png" alt="">
            <div>{{$t('layout.Buy')}}MT/ULME</div>
          </div>
          <!-- LP 流动池 -->
          <div class="flex_row menu_list" @click="toPage('/LP')">
            <img src="@/image/menu/caidanlan_33.png" alt="">
            <div>{{$t('layout.LP')}}</div>
          </div>
          <!-- MTDAO ID VIP -->
          <div class="flex_row menu_list"  @click="toPage('/id_level')">
            <img src="@/image/menu/vip.png" alt="">
            <div>MTDAO ID VIP</div>
          </div>
          <!-- 投票治理 -->
          <div class="flex_row menu_list"  @click="toPage('/vote_list')">
            <img src="@/image/menu/toupiao.png" alt="">
            <div>{{$t('layout.Vote')}}</div>
          </div>
          <!-- 发展规划 -->
          <div class="flex_row menu_list"  @click="replacePage('/planning')">
            <img src="@/image/menu/guihua.png" alt="">
            <div>{{$t('layout.Planning')}}</div>
          </div>
          <!-- 退出登录 -->
          <!-- <div class="flex_row menu_list">
            <div>{{$t('layout.Out')}}</div>
          </div> -->
          <!-- 关于我们 -->
          <!-- <div class="flex_row menu_list" @click="replacePage('/about_us')">
            <i class="ifont icone-guanyuwomen"></i>
            <div>{{$t('layout.About')}}</div>
          </div> -->
        </div>
        <!-- 选择语言 -->
        <div class="language" @touchmove.prevent @mousewheel.prevent>
          <div class="mask" style="bottom: 0;z-index: 0;background:0;" @click="show_language=false" v-if="show_language"></div>
          <div class="language_chose flex_lr hand" @click="show_language=true">
            <div class="language_title flex_center">{{$t('layout.lang')}}</div>
            <div class="language_show flex_center">
              <div class="flex_row">
                <div>{{$t('layout.Current_language')}}</div>
                <i class="ifont icone-sanjiaoxing" style="font-size:12px;margin-left:5px;"></i>
              </div>
            </div>
          </div>
          <!-- 语言列表 -->
          <div class="language_box" v-if="show_language">
            <div class="language_list hand flex_center" @click="change('cn')">繁体中文</div>
            <div class="language_list hand flex_center" @click="change('en')">English</div>
            <div class="language_list hand flex_center" @click="change('jpn')">日本語</div>
            <div class="language_list hand flex_center" @click="change('kr')">한국</div>
          </div>
        </div>
        
      </div>
    </div>
    <!-- 占位 -->
    <div style="height: 50px;"></div>
    <div class="y_center">
      <router-view :style="'width:'+$setWidth"/>
    </div>
  </div>
</template>
 
<script>
import walletHelper from "@/utils/walletHelper.js"

export default {
  name: "HelloWorld",
  props: {},
  data() {
    return {
      is_menu: false,
      isActive: false,
      isClose: false,
      show_language:false,
      menu_open_list:[],
    };
  },
  computed: {
		wallet_address() {
			return this.$store.state.user.wallet_address
		},
	},
  mounted() {

  },
  created() {
    if(window.ethereum!=undefined){
      //遇到钱包多账户切换的时候，需要使用监听事件
      ethereum.on("accountsChanged", function(accounts) {
        console.log('钱包切换',accounts)
        window.location.reload();
      });
      //当所连接网络ID变化时触发
      ethereum.on("chainChanged",(networkIDstring)=>{
        console.log('链切换',networkIDstring)
        walletHelper.init_wallet()
        window.location.reload();
      });
    }

    },
  methods: {
    async getadd(){
      console.log(1122)
      let address=await walletHelper.connectMetamask()

    },
    // 展开/收起菜单
    openMenu(name){
      var index = this.menu_open_list.indexOf(name)
      if(index>-1){
        this.menu_open_list.splice(index,1)
      }else{
        this.menu_open_list.push(name)
      }
    },
    // 选择语言
    change(type) {
      this.closeDrop()
      this.show_language=false
      this.$i18n.locale = type
      localStorage.setItem('language',type)
      this.$store.commit('user/SET_LANGUAGE', type);
    },
    // 打开菜单
    clickBtn() {
      this.is_menu = true;
      this.isActive = true;
      this.isClose = false;
    },
    // 关闭菜单
    closeDrop() {
      this.isClose = true;
      this.show_language = false;
      setTimeout(() => {
        this.is_menu = false;
      }, 200);
    },
    // 敬请期待
    coming_soon(){
      this.closeDrop()
      this.$toast(this.$t('tips.title'));
    },
    // 邀请分享
    goShare(){
      if(this.wallet_address.slice(0,2)!='0x'){
        this.$toast({
          title:this.$t('tips.title4'),
          type:'error'
        })
        return
      }
      this.closeDrop()
      this.$router.push('/share/'+this.wallet_address)
    },
    verification(fn){
      if(this.$store.state.user.verification||process.env.VUE_APP_DEBUG){
        fn()
        return
      }
      walletHelper.getWeb3().eth.sign(walletHelper.getWeb3().utils.keccak256("Hello world"), walletHelper.getAddress()).then(e=>{
        this.$store.commit('user/SET_VERIFICATION', true)
        fn()
      },e=>{
        console.log(1111111111)
      })
    },
    // 设置
    setUp(){
      const that=this
      this.closeDrop()
      if (process.env.VUE_APP_DEBUG) {
          that.$router.push('/setUp')
          return
      }
       walletHelper.getContract('daoV3').methods.isMinter(walletHelper.getAddress()).call().then((result)=>{
          if(result){
            that.$router.push('/setUp')
          }else{
            walletHelper.getContract('daoV1').methods.isMinter(walletHelper.getAddress()).call().then((result)=>{
            if(result){
              that.$router.push('/setUp')
            }else{
              that.coming_soon()
            }
            })
            
          }
        },e=>{
          that.coming_soon()
        })
    },
    replacePage(url){
      this.closeDrop()
      this.$router.replace(url).catch(err => err)
    },
    toPage(url){
      this.closeDrop()
      this.$router.push(url)
    },
    goSwap(){
      this.closeDrop()
      if(walletHelper.getChainId()==97){
        window.open('http://fishapi.h5zn.cn/#/swap')
      }else{
        window.open('https://pancakeswap.finance/swap')
      }
    }
  }
};
</script>
 
<style scoped lang="scss">
::-webkit-scrollbar{display:none;}
.pc{
  transform:scale(1.5);
  transform-origin: 50% 0;
  display:inline-block;
}
.head{
  position: fixed;
  z-index: 999;
  top: 0;
  width: 100%;
  height: 50px;
  // background: linear-gradient(0deg, #C4C4C4, #FFFFFF, #C4C4C4);
  background: #01122A;
  color:#FFFFFF;
  padding: 0 5px;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.5);
  i{
    // color: #BF8507;
    color: #FFFFFF;
    font-size: 20px;
    margin-right: 5px;
  }
  img{
    height: 30px;
  }
  .head_user{
    // background: #BF8507;
    padding: 2px 3px;
    border-radius: 5px;
    width: 100%;
  }
  .head_headimg{
    height: 30px;
    width: 30px;
    border-radius: 50%;
    margin-left: 5px;
  }
}
.menu {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba($color: #000000, $alpha: 0.5);
  z-index: 998;
  .menu_bg {
    width: 230px;
    position: absolute;
    top: 50px;
    left: 0;
    bottom: 0;
    color: #FFFFFF;
    // background: linear-gradient(90deg, #C4C4C4, #FFFFFF, #C4C4C4);
    background: #01122A;
    .menu_list{
      padding: 8px 15px;
      font-size: 14px;
      line-height: 22px;
      cursor: pointer;
      user-select: none;
      img{
        width: 22px;
        margin-right: 10px;
      }
      i{
        color: #ffffff;
        font-size: 12px;
      }
    }
    .menu_list_open{
      background: #00193d;
      padding-left: 32px;
    }
  }
  .language{
    position: relative;
    margin: 20px auto;
    width: 180px;
    height: 40px;
    .language_chose{
      width: 100%;
      height: 100%;
      border: 3px solid #063372;
      border-radius: 5px;
      background: #063372;
    }
    .language_title{
      background: #063372;
      color: #FFFFFF;
      width: 40%;
      height: 100%;
    }
    .language_show{
      width: 60%;
      height: 100%;
      background: #FFFFFF;
      border-radius: 3px;
      color: #063372;
    }
    .language_box{
      position: absolute;
      bottom: 40px;
      background: #FFFFFF;
      width: 100%;
      z-index: 1;
      .language_list{
        height: 35px;
        color: #063372;
      }
    }
  }
  // 开
  .active {
    animation: opendoor 0.3s normal forwards;
  }
  @keyframes opendoor {
    from {
      transform: translateX(-100%);
    }
    to {
      transform: translateX(0);
    }
  }
  // 关
  .close {
    animation: close 0.3s normal forwards;
  }
  @keyframes close {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(-100%);
      opacity: 0;
    }
  }
}
</style>